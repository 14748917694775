import {compare} from 'compare-versions';

/**
 * Returns ReactNative app version via parsing user-agent (only works client-side currently)
 */
export const getReactNativeVersion = (): string => {
    const reactNativeMatchArray = navigator?.userAgent?.match(/Imperial React Native\/?(\d{1,3}\.\d{1,3}\.\d{1,3})?/);
    return reactNativeMatchArray?.length > 1 && reactNativeMatchArray[1] ? reactNativeMatchArray[1] : ``;
};

export const isReactNativeMinVersion = (reactNativeMatchArray: RegExpMatchArray, minVersion: string): boolean => {
    // If minVersion, test ReactNative appVersion is greater than or equal
    if (minVersion && reactNativeMatchArray?.length > 1) {
        const appVersion = reactNativeMatchArray[1];
        return !!appVersion && compare(appVersion, minVersion, `>=`);
    }

    // Else determine userAgent is ReactNative
    return !!reactNativeMatchArray;
};
